<template>
  <div id="app">
    <!-- <b-row>
      <b-col sm="4">
        <b-form-group>
          <label for="Title">Event List with Close Seating</label>

          <b-form-select
            v-model="event_id"
            :options="event_options"
            v-on:change="audupdated"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="12">
        <div class="seat_layout_container">
          <div class="section-seatlayout">
            <canvas id="upper-canvas"></canvas>
          </div>
        </div>
      </b-col>
    </b-row> -->
    <b-button
      class="mr-2"
      @click="goToSeatLayout()"
      size="sm"
      variant="success"
    >
      Create/Update Seat Layout
    </b-button>
  </div>
</template>

<script>
import MasterService from "@/services/MasterService";
import UploadService from "@/services/UploadService";
import { Switch as cSwitch } from "@coreui/vue";
import { VueEditor } from "vue2-editor";

export default {
  data() {
    return {
      status_options: [
        { value: 1, text: "Block" },
        { value: 2, text: "Un Block" },
      ],
      status_id: 1,
      schedule_options: [],
      schedule_id: null,
      listArr: [],
      arrToText: "",
      event_id: null,
      event_options: [],

      event_info: {
        country_id: null,
        e_public: 1,
        e_name: "",
        e_s_description: "",
        e_description: "",
        e_image: "",
        es_date: "",
        ee_date: "",
        e_book_type: "null",
        terms_condition: "",
        booking_open: true,
        is_unlisted: true,
        is_free: true,
        ec_id: "1",
        additional_information: "null",
        rating: "",
        e_genreData: [
          {
            e_genreData: "",
          },
        ],
        cast_crew: [
          {
            cc_name: "cc_name",
            cc_type: "cc_type",
            cc_picture: "cc_picture",
          },
        ],
        e_banners: [
          {
            e_banners: "",
          },
        ],
        e_videos: [
          {
            e_videos: "",
          },
        ],
        organiser_details: [
          {
            od_name: "",
            od_sdescription: "",
            od_description: "",
            od_logo: "",
            od_email: "",
            od_mobile: "",
          },
        ],
        sponsor_details: [
          {
            event_id: "event_id",
            sd_name: "sd_name",
            sd_sdescription: "sd_sdescription",
            sd_description: "sd_description",
            sd_logo: "sd_logo",
            sd_email: "sd_email",
            sd_mobile: "123456789",
          },
        ],
        venue: [
          {
            event_id: "1",
            ev_name: "",
            ev_address: "",
            ev_lat: "",
            ev_long: "",
            ev_city: "",
            ev_country: "",
            auditorium: [
              {
                event_venue_id: "1",
                a_name: "",
                a_description: "",
                a_row: "0",
                a_col: "0",
                booking_type: "null",
                max_capacity: "0",
                area_type: [
                  {
                    area_name: "",
                    max_capacity: "",
                    seat_type: [
                      {
                        auditorium_id: "1",
                        st_name: "",
                        price: "",
                        currency: "",
                      },
                    ],
                  },
                ],
                seat_layout: [],
                schedule: [
                  {
                    auditorium_id: "1",
                    event_id: "1",
                    es_date: "",
                    es_time: "",
                  },
                ],
              },
            ],
          },
        ],
      },
    };
  },
  beforeMount() {
    MasterService.closeSeatLayoutEventList()
      .then((response) => {
        // console.log(response.data.List, "countryyyyy");
        // console.log("object :>> ", response.data.data);
        this.event_options = response.data.data;
        console.log("event_options :>> ", this.event_options);
      })
      .catch((error) => {
        console.log("Catch on Getting Country Event Error: ", error);
      });
  },

  mounted() {},

  methods: {
    goToSeatLayout() {
      window.location.href =
        "https://admin-wanasatime.wanasatime.com/seatlayout/?ss=2";
    },
    audupdated(value) {
      console.log("object :>> ", value);
      if (value) {
        MasterService.getAudSchedule(value)
          .then((response) => {
            // console.log(response.data.List, "countryyyyy");
            // console.log("object :>> ", response.data.data);
            this.schedule_options = response.data.data;
            console.log("schedule_options :>> ", this.schedule_options);
          })
          .catch((error) => {
            console.log("Catch on Getting Country Event Error: ", error);
          });
      }
    },
    textToArr(value) {
      this.listArr = value
        .split(",")
        .map((item) => item.trim())
        .filter((item) => item.length > 0);
    },
    handleSubmit() {
      console.log("object :>> ", this.schedule_id, this.listArr);
      if (this.listArr.length > 0 && this.schedule_id) {
        // blockScheduleSeats(this.schedule_id, this.listArr)
        MasterService.blockScheduleSeats(this.schedule_id, this.status_id, {
          seats: this.listArr,
        })
          .then((response) => {
            console.log("response.data.message :>> ", response.data.message);
            alert(response.data.message);
            // console.log(response.data.List, "countryyyyy");
            // console.log("object :>> ", response.data.data);
            // this.schedule_options = response.data.data;
            // console.log("schedule_options :>> ", this.schedule_options);
          })
          .catch((error) => {
            console.log("Catch on Getting Country Event Error: ", error);
          });
      }
    },
  },
};
</script>

<style>
.card-header {
  background: none !important;
}

.status-alignment {
  margin-top: 1.3rem;
}
.rmv-btn-alignment {
  margin-top: 2.5rem;
}
</style>
